(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/grassroots-toaster/assets/javascripts/grassroots-toaster.js') >= 0) return;  svs.modules.push('/components/tur/grassroots-toaster/assets/javascripts/grassroots-toaster.js');

'use strict';

svs.tur = svs.tur || {};
svs.tur.GrassrootsToaster = function () {
  const show = args => {
    const dismissed = svs.components.Storage.browser.get('toasterdismissed', 'grassroots'),
      dismissedCount = svs.components.Storage.browser.get('toasterdismissedcount', 'grassroots'),
      count = dismissedCount ? dismissedCount.count : 0;
    if (count < 3 && !dismissed) {
      $.when(svs.tur.usersettings.get({
        key: 'receivers'
      })).done(res => {
        if (res.value && !res.value.length) {
          const dialog = new svs.ui.dialog.Confirm({
            branding: svs.ui.dialog.branding.TUR,
            icon: 'favorites',
            autoClose: false,
            area: svs.ui.dialog.area.TOASTER,
            type: svs.ui.dialog.type.DEFAULT,
            title: 'Stötta dina favoritföreningar med Gräsroten',
            message: [{
              type: svs.ui.dialog.message.TEXT,
              text: 'Välj upp till tre favoritföreningar som får ditt stöd varje gång du spelar.'
            }],
            actions: [{
              title: count === 2 ? 'Visa inte igen' : 'Påminn mig senare',
              callback() {
                svs.components.Storage.browser.set('toasterdismissedcount', 'grassroots', {
                  count: count + 1
                }, svs.components.Storage.constants.EXPIRES_1YEAR);
                svs.components.Storage.browser.set('toasterdismissed', 'grassroots', {
                  isDismissed: true
                }, svs.components.Storage.constants.EXPIRES_1MONTH);
                dialog.close();
                args && args.onDismiss && args.onDismiss();
              }
            }, {
              title: 'Välj favoriter',
              callback() {
                location.href = svs.core.urlMapping.get('grassrootsHome');
                args && args.onSuccess && args.onSuccess();
              }
            }]
          });
          svs.ui.dialog.api.add(dialog);
        } else {
          args && args.onDismiss && args.onDismiss();
        }
      });
    } else {
      args && args.onDismiss && args.onDismiss();
    }
  };
  return {
    show
  };
};

 })(window);